<template>
  <div>
    <div style="margin-bottom: 30px;">
      <h1>Excel去重工具</h1>
      <p>
        可用于去重手机号、名单、票据等Excel，可导出去重后的新Excel<br>
        温馨提示：请输入Excel表中需要去重的列名称（如手机号、姓名）
      </p>
    </div>
    <el-space wrap>
      <el-input v-model="namekey" placeholder="去重唯一列名称" />
      <el-upload ref="uploadRef" :limit="1" :on-change="handleFileChange" :auto-upload="false" :show-file-list="false"
        accept=".xls,.xlsx">
        <template #trigger>
          <el-button type="primary">选择名单</el-button>
        </template>
      </el-upload>
      <el-button @click="clearData" type="danger">重置数据</el-button>
      <el-button :disabled="!dataList.length" @click="exportExcel">导出名单</el-button>
    </el-space>
    <p>总数{{totalNum}} 条，去重后 {{dataList.length}} 条</p>
  </div>
  <div style="margin-top: 40px;">
    <h5>示例图</h5>
    <img src="./assets/demo.pic.jpg" />
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
export default {
  name: 'App',
  components: {
    // 组件内部注册的子组件
  },
  data() {
    return {
      namekey:'',
      totalNum: 0,
      dataList: [],
      mobileList: [],
      exportData: []
    }
  },
  methods: {
    // 数据去重
    dealExcel(ws){
      // 总数
      this.totalNum = this.totalNum + ws.length
      // 数据集合
      this.dataList = [...this.dataList, ...ws]

      // 手机号集合
      let mobileList = []
      for (let i = 0; i < ws.length; i++) {
        mobileList.push(ws[i][this.namekey])
      }

      // 去重
      this.mobileList = Array.from(new Set([...this.mobileList, ...mobileList]));
      console.log('mobileList', this.mobileList.length)
      this.dataList = this.mobileList.map((v) => {
        return this.dataList.find((item) => item[this.namekey] == v)
      })
      // console.log('newData', this.dataList)
    },
    // 导入数据
    handleFileChange(rawFile){
      // 读取表格
      const _this = this;
      console.log(this, 'this')
      const fileReader = new FileReader()
      fileReader.onload = (ev) => {
        const workbook = XLSX.read(ev.target.result, {
          type: 'binary'
        })
        const wsname = workbook.SheetNames[0]
        const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname])
        _this.dealExcel(ws)

        // 重置按钮
        _this.$refs.uploadRef.clearFiles()
      }
      fileReader.readAsBinaryString(rawFile.raw)
    },
    // 导出新表
    exportExcel() {
      const header = Object.keys(this.dataList[0]);
      const worksheet = XLSX.utils.json_to_sheet(this.dataList, { header });
      XLSX.utils.sheet_add_aoa(worksheet, [[null]], { origin: -1 });
      XLSX.utils.sheet_add_json(worksheet, this.dataList, { skipHeader: true, origin: 'A2' });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, '去重数据.xlsx');
    },
    // 重置数据
    clearData() {
      this.dataList = []
      this.mobileList = []
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
